<template>
  <action-dispatcher action="classes/get">
    <div class="view-homeworks">
      <h4 class="mb-4">Você deseja ver as atividades de qual turma?</h4>
      <div
        v-for="(c, i) in classes"
        :key="`class-${i}`"
        class="card d-flex flex-row align-items-center p-3 mb-4"
      >
        <router-link
          class="flex-fill"
          :to="{ name: 'admin-class-homework', params: { id: c.id } }"
        >
          {{ c.name }}
        </router-link>
      </div>
    </div>
  </action-dispatcher>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("classes", ["classes"])
  }
};
</script>
<style lang="scss" scoped>
.view-homeworks {
}
</style>
